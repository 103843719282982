import React, { useEffect, useState } from "react"

const NavigationView = ({
  timelineLength,
  timelinePosition,
  onSliderPositionChange,
  onNavigatePrevious,
  previousBtnEnabled,
  onNavigateNext,
  nextBtnEnabled,
}) => {
  const [timeoutHandle, setTimeoutHandle] = useState(undefined)
  const [sliderPosition, setSliderPosition] = useState(0)

  useEffect(() => {
    if (timelinePosition && timelinePosition !== sliderPosition) {
      setSliderPosition(timelinePosition)
    }
    // eslint-disable-next-line
  }, [timelinePosition])

  const handleSliderChange = (e) => {
    if (timeoutHandle !== undefined) {
      window.clearTimeout(timeoutHandle)
    }

    const newProgress = parseInt(e.target.value)
    //To update position immediately
    setSliderPosition(newProgress)

    setTimeoutHandle(
      window.setTimeout(() => {
        onSliderPositionChange(newProgress)
      }, 500)
    )
  }

  return (
    <div
      className="d-flex flex-row justify-content-between align-items-center mx-3"
      style={{ marginBottom: 20 }}
    >
      <div
        className={`bg-${
          previousBtnEnabled ? "success" : "secondary"
        } rounded-pill align-self-start d-flex justify-content-center`}
        style={{
          cursor: previousBtnEnabled ? "pointer" : "default",
          padding: "5px 15px",
          minWidth: "100px",
        }}
        onClick={onNavigatePrevious}
      >
        <span
          style={{
            fontWeight: "bold",
            fontSize: 18,
            color: "#fff",
            textAlign: "center",
          }}
        >
          {"Previous"}
        </span>
      </div>
      <div className="d-flex flex-column align-items-center w-100 position-relative">
        <>
          {/* <div>{calculateProgressPercentage(sliderPosition)} %</div> */}
          {/* TODO: revisar aca por que no muestra bien el indice de pagina */}
          {timelineLength ? (
            <div className="position-absolute" style={{ top: "-1rem" }}>
              Page {sliderPosition} of {timelineLength}
            </div>
          ) : null}
          <nav
            className="px-3 w-100"
            style={{
              display: "flex",
              flexGrow: 1,
            }}
          >
            <input
              className="book-navigator"
              type="range"
              id="slider"
              style={{
                display: "flex",
                flexGrow: 1,
                width: "100%",
                backgroundColor: "transparent",
              }}
              min={0}
              max={timelineLength}
              value={sliderPosition}
              onChange={handleSliderChange}
            />
          </nav>
        </>
        {/* <span className="text-center">
            {this.book.fileType === BOOK_FILE_TYPES.PDF
              ? `Page ${
                  this.state.pageNumber || (this.state.numPages ? 1 : "--")
                } of ${this.state.numPages || "--"}`
              : BOOK_FILE_TYPES.EPUB
              ? "-%"
              : ""}
          </span> */}
      </div>
      <div
        className={`bg-${
          nextBtnEnabled ? "success" : "secondary"
        } rounded-pill align-self-start d-flex justify-content-center`}
        style={{
          cursor: nextBtnEnabled ? "pointer" : "default",
          padding: "5px 15px",
          minWidth: "100px",
        }}
        onClick={onNavigateNext}
      >
        <span
          style={{
            fontWeight: "bold",
            fontSize: 18,
            color: "#fff",
            textAlign: "center",
          }}
        >
          {"Next"}
        </span>
      </div>
    </div>
  )
}
export default NavigationView
